.logo {
  cursor: pointer;
}

body {
  display: grid;
  justify-content: center;
}

input {
  height: 3rem;
}

.signUpForm {
  display: grid;
  margin: 40px auto;
  padding: 20px;
  justify-content: center;
  justify-items: center;
  max-width: 1159px;
  p {
    font-size: 16px;
    text-align: start;
  }

  ol {
    @media (min-width: 1024px) {
      padding: 0;
    }
  }

  li {
    margin-bottom: 20px;
  }

  @media (min-width: 1024px) {
    margin: 20px;
    p {
      text-align: center;
    }
  }
}

.formContainer {
  display: grid;
  justify-content: center;
  justify-items: center;
  margin: 20px;
}

.signUpHeading {
  font-size: 46px;
  font-family: 'Tenor Sans', sans-serif;
  font-weight: 600;
  font-style: normal;

  @media (min-width: 1024px) {
    text-align: center;
  }
}

.subHeading {
  font-size: 28px !important;
  text-align: justify;
  text-align-last: unset !important;
}

.semiSubHeading {
  font-size: 22px !important;
}

.list {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.emailInput {
  width: 80%;
  height: 60px;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 5px;
  padding: 0 20px;

  @media (min-width: 1024px) {
    width: 250px;
    height: 50px;
  }
}

.submitButton {
  background-color: #e02c3c;
  color: white;
  border: none;
  width: 80%;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  border-radius: 5px;

  @media (min-width: 1024px) {
    width: 250px;
    height: 50px;
  }
}
