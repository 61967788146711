body {
  font-family: 'cabin';
  margin: 0 20px;
  color: rgb(61, 60, 60);
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 20px;
  margin: 0;
}

h3 {
  font-size: 16px;
  margin: 0;
}

p {
  font-size: 14px;
}

button {
  cursor: pointer;
}

.subHeading {
  font-size: 16px;
  text-align: center;
  text-align-last: center;
  @media (min-width: 1024px) {
    text-align: justify;
  }
}

button {
  background-color: #e02c3c;
  color: white;
  border: none;
  width: 80%;
  height: 60px;
  font-size: 16px;
  font-weight: 600;

  @media (min-width: 1024px) {
    width: 250px;
    height: 50px;
  }
}

.header {
  display: grid;
  justify-content: center;
  margin: 20px;
}

.logo {
  width: 150px;
}

.App-header {
  display: grid;
  grid-template-rows: auto auto 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr auto 1fr;
    justify-items: stretch;
    justify-content: space-between;
  }
}

.imageFitContainer {
  width: 100%;
  height: auto;
  object-fit: cover;

  @media (min-width: 1024px) {
    width: 300px;
    height: 100%;
  }
}

.mobileImage {
  width: 100%;
  border-radius: 10px;
}

.desktopImage {
  justify-self: end;
}

.headerText {
  display: grid;
  grid-template-rows: auto auto auto;
  text-align: center;
  min-width: 280px;
  padding: 20px;
  justify-items: center;
  max-width: 700px;
  justify-self: center;

  @media (min-width: 1024px) {
    padding: 70px 90px;
  }
}

.howItWorksContainer {
  display: grid;
  justify-items: center;
  align-items: center;
  text-align: center;
  grid-template-rows: auto 1fr auto;
  margin: 0 30px;
  padding: 40px 0;
  gap: 30px;
  border: 1px #e0dfda solid;
  @media (min-width: 1024px) {
    margin: 30px 100px;
  }
}

.explainerContainer {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;

  .explainerText:not(:first-child) {
    border-top: 1px #e0dfda solid;
    border-left: none;
  }

  @media (min-width: 1024px) {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;

    .explainerText:not(:first-child) {
      border-left: 1px #e0dfda solid;
      border-top: none;
    }
  }
}

.explainerText {
  padding: 20px;
  text-align: center;
}

.imageBackgroundContainer {
  position: relative; /* Establishes a positioning context */
  width: 100%; /* Container takes full width of its parent */
  max-width: 936px; /* Maximum width of the container similar to the image you provided */
  margin: auto; /* Centers the container */

  @media (max-width: 768px) {
    padding: 30px 0;
  }
}

.imageBackgroundContainer img {
  width: 100%; /* Image takes the full width of its parent */
  display: block; /* Removes any default inline spacing */
}

.overlay {
  position: absolute; /* Positions overlay in relation to the container */
  top: 30%; /* Positions the overlay in the center of the container */
  left: 30%; /* Positions the overlay in the center of the container */
  transform: translate(
    -50%,
    -50%
  ); /* Offsets the overlay by half its width and height to truly center it */
  background-color: rgba(255, 255, 255); /* White background with opacity */
  padding: 3em; /* Padding around the text */
  width: 35%; /* The overlay width; adjust as necessary */

  max-width: 600px; /* Maximum width of the overlay */
  text-align: start; /* Centers the text inside the overlay */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow around the overlay */
}

@media (max-width: 768px) {
  .overlay {
    transform: none;
    margin: 10px; /* Add some margin to prevent the overlay from touching the edges */
    padding: 1em; /* Adjust padding */
    width: auto; /* Let the width be determined by the content and viewport */
    left: 0;
  }
}

.overlay h2 {
  margin-top: 0; /* Removes default top margin */
  margin-bottom: 0.5em; /* Space between the heading and the paragraph */
}

.overlay p {
  margin: 0; /* Removes default paragraph margins */
}

.QandAContainer {
  display: grid;
  grid-template-columns: 30px 1fr;
  padding: 30px 20px;
  @media (min-width: 1024px) {
    padding: 80px 30%;
  }
}

.inTheNewsContainer {
  display: grid;
  background-color: #f0efed;
  justify-items: center;
  align-items: center;
  text-align: center;
  grid-template-rows: auto 1fr;
  margin: 0 30px;
  padding: 40px 0;
  gap: 30px;
  border: 1px #e0dfda solid;
  @media (min-width: 1024px) {
    margin: 30px 100px;
  }
}

.newsArticles {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  @media (min-width: 1024px) {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;

    .explainerText:not(:first-child) {
      border-left: 1px #e0dfda solid;
      border-top: none;
    }
  }
}

.newsArticle {
  margin: 40px;
  padding: 20px;
  background-color: white;
}

.newsHeadings {
  padding: 10px 0 20px 0;
  border-bottom: 1px #e0dfda solid;
}

.testimonialContainer {
  display: grid;
  justify-items: center;
  align-items: center;
  text-align: center;
  grid-template-rows: auto 1fr;
  margin: 0 30px;
  padding: 40px 0;
  max-width: 800px;
  @media (min-width: 1024px) {
    margin: 30px auto;
  }
  place-items: center;
}

.testimonials {
  display: grid;
  grid-template-rows: 1fr 1fr;
  @media (min-width: 1024px) {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;

    .explainerText:not(:first-child) {
      border-left: 1px #e0dfda solid;
      border-top: none;
    }
  }
  justify-items: center;
}

.testimonial {
  display: grid;
  margin: 10px;
  padding: 20px;
}

.ctaContainer {
  display: grid;
  justify-items: center;
}

.getStartedButton {
  margin: 20px 0 100px 0;
  max-width: 300px;
}

.noSubscription {
  padding: 20px;
}
